.badge {
    &.badge-lg {
        padding: 0.9375rem 2.5rem;
        font-size: 1rem;
        font-weight: $font-weight-bold;
    }
    
    &.badge-circle {
        @include border-radius(50%);
        text-align: center;
        height: 0;
        width: 32px;
        padding: 16px 0;

        &,
        >* {
          line-height: 0;
        }
    }
    &.badge-rounded {
        border-radius:35px;
        padding:1rem; 
        background:$badge-bg-blue;
        color:#fff;
        margin-right:5px;
    }

    &[class*="badge-outline-"] {
        background-color: transparent !important;
        border: 1px solid $slate-gray-light;
        color: $slate-gray-light;
    }
}