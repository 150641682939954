#retail {
  $primary: #226666;
  background-color: #ffffff;

  .bg-primary{
    background-color: $primary !important;
  }

  .text-warning {
    color: #cc771c !important;
  }

  .container-wrap {
    padding-top: 0;

    .navbar-main {
      position: unset;
      height: 78px;

      i.fa-arrow-left,
      i.fa-arrow-right {
        @media (min-width: 992px) {
          font-size: 2em;
        }
      }

      .topbar__button-icon {
        fill: #000000;
      }

      .topbar__button {
        display: flex !important;
      }

      #dropdownMenuButton {
        @media (max-width: 576px) {
          padding-right: 12px;

          &::before {
            right: 0px;
          }
        }
      }
    }
  }

  //sidebar
  #navbarAsideContent {
    padding-top: 3px;
    background: #082f4d;

    @media (min-width: 992px) {
      &.sidebar--small {
        .nav-link {
          &:hover {
            background-color: #1b5480;
          }
        }

        .settings {
          transition: all 0.3s;

          .time {
            display: none;
            transition: opacity 1s ease-out;
            opacity: 0;
          }

          .icon {
            transition: all 0.3s;
          }
        }
      }
    }

    @media (max-width: 576px) {
      overflow: unset;
    }

    .settings {
      border-top: 1px solid #1b5480;

      .time {
        transition: all 0.3s;
      }
    }

    .nav-scroller {
      height: calc(100vh - 41px);
      overflow-x: unset;
      overflow-y: unset;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .nav-link {
      color: #fff;
      padding: 20px 0;

      .nav-link-icon {
        color: #fff;

        svg,
        .a {
          color: #fff;
          fill: #fff;
        }
      }

      p {
        color: #ffffff;
        top: 21px;
      }
    }

    li.nav-item {
      margin-bottom: 0;

      &.active,
      &:hover {
        svg,
        svg .a {
          fill: #fff;
        }

        svg path {
          stroke: #fff;
        }

        svg rect {
          stroke: #fff;
        }

        svg circle {
          stroke: #fff;
        }

        svg line {
          stroke: #fff;
        }

        svg polyline {
          stroke: #fff;
        }

        svg polygon {
          stroke: #fff;
        }

        a {
          background-color: #1b5480;
        }
      }
    }
  }

  .sidebar--small {
    .mobile-toggle {
      opacity: 1;
    }
  }

  .mobile-toggle {
    @media (max-width: 768px) {
      position: absolute;
      right: -71px;
      padding: 18px 27px;
      opacity: 0;

      i {
        font-size: 24px;
      }
    }
  }

  //tabs
  .nav-tabs .nav-link {
    color: $primary;
    background: #f4f4f4;
    border-radius: 6px 6px 0px 0;
    border-bottom: 1px solid;
    text-align: center;
    padding: 0.8rem 1rem;
    white-space: nowrap;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #226666;
    border-color: #226666;
    font-weight: bold;
  }

  .nav-tabs {
    border-bottom: 1px solid #226666;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: auto;

    li.nav-item {
      flex-grow: 1;
    }
  }

  .nav-tabs .nav-item.show .nav-link:after,
  .nav-tabs .nav-link.active:after {
    content: unset;
  }

  .navbar-truncate{
    width: 125px;

    h5{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media (min-width: 768px) {
      width: unset;
    }
  }
  //empty state
  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    height: calc(100vh - 270px);

    img {
      width: 240px;

      @media (max-width: 768px) {
        width: 122px;
      }
    }
  }

  //buttons
  .btn {
    border-radius: 5px;
  }

  .fa-ellipsis-h {
    width: 20px;
    height: 20px;
  }

  .btn-primary {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
  }

  .btn-outline-primary {
    color: $primary;
    border-color: $primary;

    &:hover {
      background-color: $primary;
      color: #fff;
    }
  }

  .text-deep-primary {
    color: $primary !important;
  }

  .badge-primary {
    color: #fff;
    background-color: $primary;
  }

  .fa-ellipsis-h {
    color: $primary !important;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: $primary !important;
    color: #fff !important;
  }

  .text-primary {
    color: $primary !important;
  }

  .bg-light {
    background-color: #f4f4f4 !important;
  }

  .btn-outline-primary:not(:disabled):not(.disabled).active,
  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .show > .btn-outline-primary.dropdown-toggle {
    background-color: #ffffff !important;
    color: #266 !important;
  }

  //radio
  .form-check-input[type="radio"] + label:after {
    background: $primary;
  }

  .form-check-input[type="radio"]:checked + label:before {
    border: 1px solid $primary;
  }

  //checkbox
  input {
    accent-color: $primary;
  }

  //table
  table {
    border-collapse: separate;
    border-spacing: 0 13px;

    th {
      vertical-align: middle;
    }

    &.normal {
      border-collapse: unset;
      border-spacing: unset;
      margin-bottom: 0;

      tr {
        background-color: white;
      }
    }

    tr {
      background-color: #f4f5f7;
      border-radius: 5px;

      td {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        &:first-child {
          border-radius: 5px 0 0 5px;
        }

        &:last-child {
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }

  //alert

  .alert-success {
    background-color: #b7efc1;
    color: $primary;
    border: 0;
    border-bottom: 1px solid #b7efc1;
  }

  //pagination
  .pagination .page-item.active .page-link {
    background-color: #226666;
    color: #ffffff;
    border-color: #226666;
    border-radius: 4px;
  }

  //search input
  .card-search .form-inline input,
  .card-search form input {
    border-radius: 5px;
    border-color: #e5edf3;
  }

  .card-search .form-control:focus + .btn {
    color: #226666;
  }
  //order cards
  .order-summary {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;

    > div {
      height: 157px;
      padding: 25px;
      display: flex;
      align-items: center;
      justify-content: start;
      border-radius: 6px;
      flex-grow: 1;

      @media (max-width: 768px) {
        height: 85px;
        padding: 14px;
      }

      &.report-card {
        background-color: #ffe5e5 !important;
        background-image: none !important;
      }

      &:nth-of-type(1) {
        background-color: #f2f2f2;
      }

      &:nth-of-type(2) {
        background-color: #d9fbef;
        background-image: url("../images/retail/star.png");
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 25%;

        @media (max-width: 768px) {
          //   background-size: 40px;
        }
      }

      &:nth-of-type(3) {
        background-color: #fffde6;
        background-image: url("../images/retail/time.png");
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 25%;
        @media (max-width: 768px) {
          // background-size: 40px;
        }
      }

      &:nth-of-type(4) {
        background-color: #d4f0ff;
        background-image: url("../images/retail/basket.png");
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 25%;
        @media (max-width: 768px) {
          // background-size: 40px;
        }
      }

      &:nth-of-type(5) {
        background-color: #e6e6fc;
        background-image: url("../images/retail/pack.png");
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 25%;
        @media (max-width: 768px) {
          // background-size: 40px;
        }
      }
    }
  }

  //uploader
  .upload-image {
    width: 180px;
    height: 180px;
    border: 1px dashed $primary;
  }

  //toggle
  input:checked + .slider {
    background-color: $primary;
  }

  // progress bar
  .customprogressbar li.active:before {
    border-color: $primary;
    background: $primary;
  }

  .customprogressbar li.active {
    color: $primary;
  }

  //pulse effect
  $green: rgb(2, 26, 2);

  //pulse effect
  @keyframes ripple {
    from {
      box-shadow: 0 0 0 50px rgba($green, 0.1), 0 0 0 70px rgba($green, 0.1),
        0 0 0 90px rgba($green, 0.1), 0 0 0 110px rgba($green, 0.1);
    }

    to {
      box-shadow: 0 0 0 380px rgba($green, 0), 0 0 0 580px rgba($green, 0),
        0 0 0 780px rgba($green, 0), 0 0 0 980px rgba($green, 0);
    }
  }

  .new-order {
    background-color: green !important;
    color: white !important;
    position: relative;
    overflow: hidden;

    &::after {
      content: " ";
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background: #026102;
      position: absolute;
      top: 50%;
      left: 50%;
      bottom: 0;
      animation: ripple 1.5s linear infinite;
    }

    &:hover {
      color: black;
      background-color: green;

      td {
        background-color: green;
      }
    }
  }

  .late-order {
    background-color: #e82232 !important;
    color: white !important;
    position: relative;
    overflow: hidden;

    &::after {
      content: " ";
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background: #9e2123;
      position: absolute;
      top: 50%;
      left: 50%;
      bottom: 0;
      animation: ripple 1.5s linear infinite;
    }

    &:hover {
      color: black;
      background-color: #e82232;

      td {
        background-color: #e82232;
      }
    }
  }

  /* quantity input */
  .quantity {
    position: relative;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .quantity input {
    width: 174px;
    height: 40px;
    line-height: 1.65;
    float: left;
    display: block;
    padding: 0;
    margin: 0;
    padding-right: 20px;
    padding-left: 12px;
    border: 1px solid $primary;
    text-align: left;
    border-radius: 4px;
  }

  .quantity input:focus {
    outline: 0;
  }

  .quantity-nav {
    float: left;
    position: relative;
    height: 40px;
    display: flex;
  }

  .quantity-button {
    position: relative;
    cursor: pointer;
    // border-left: 1px solid #eee;
    width: 34px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    background: $primary;
    font-size: 21px;
    line-height: 1.7;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .quantity-button.quantity-up {
    position: absolute;
    height: 100%;
    top: 0;
    left: -34px;
    // border-bottom: 1px solid #eee;
    // border-top-left-radius: .25rem !important;
    // border-bottom-left-radius: .25rem !important;

    &::before {
      content: "";
      position: absolute;
      height: 21px;
      width: 1px;
      background: $primary;
      right: 0px;
      top: 10px;
      bottom: 0px;
      border-radius: 10px;
    }
  }

  .quantity-button.quantity-down {
    position: absolute;
    bottom: 0px;
    height: 100%;
    border-bottom-right-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  // dasboard
  .dashboard-body {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 250px 250px 530px auto;
    grid-template-areas:
      "card1"
      "card3"
      "card2"
      "card4";
    grid-gap: 1.5rem;

    @media (min-width: 768px) {
      margin: 0;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 250px 250px auto;
      grid-template-areas:
        "card1 card2"
        "card3 card2"
        "card4 card4";
      grid-gap: 1.5rem;
    }
  }

  .map {
    grid-area: card1;
  }

  .customer-views {
    grid-area: card2;

    #scroll {
      height: 460px;
    }
  }

  .customer-activity {
    grid-area: card3;

    #scroll {
      height: 185px;
    }
  }

  .trending-items {
    grid-area: card4;
  }

  .delivery-details {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "status"
      "card1"
      "card2"
      "card3"
      "card4";
    grid-gap: 1.5rem;

    @media (min-width: 768px) {
      margin: 0;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;
      grid-template-areas:
        "status status"
        "card1 card2"
        "card3 card4";
      grid-gap: 1.5rem;
    }
  }

  #order-body {
    grid-area: status;
  }

  #rider-details {
    grid-area: card1;
  }

  #pickup-item {
    grid-area: card2;
  }

  #customer {
    grid-area: card3;
  }

  #delivery-location {
    grid-area: card4;
  }

  .bg-faded-green-light {
    background-color: #f2faf8 !important;
  }

  .checked {
    color: #ffbb00 !important;
  }

  .custom-switch {
    input:checked ~ label:before {
      background-color: $primary;
      border-color: $primary;
    }
  }

  .form-check {
    input[type="radio"] {
      accent-color: $primary;
    }
  }

  .transaction-table{
    table {
      border-collapse: unset;
      border-spacing: unset;
  
      th {
        vertical-align: unset;
      }
  
      &.normal {
        border-collapse: unset;
        border-spacing: unset;
        margin-bottom: unset;
  
        tr {
          background-color: unset;
        }
      }
  
      tr {
        background-color: unset;
        border-radius: unset;
        
  
        td {
          padding-top: 1rem;
          padding-bottom: 1rem;
          border-bottom: 1px solid #d3e4ef !important;
        }
      }
    }
  }
}

.table-responsive {
  min-height: unset !important;
} 

// Progress Bar


$borderWidth: 5px;
$animationTime: .5s;
$border-color-default: #FFFFFF;
$border-color-fill: #9CABB8;
$size: 70px;
$howManySteps: 100; //this needs to be even. 

.progress.retail-progress {
  width: $size !important;
  height: $size !important;
  line-height: $size !important;
  background: none;
  box-shadow: none;
  position: relative;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: $borderWidth solid $border-color-default;
    position: absolute;
    top: 0;
    left: 0;
  }
  > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .progress-left {
    left: 0;
  }
  .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: $borderWidth;
    border-style: solid;
    position: absolute;
    top: 0;
    border-color: $border-color-fill;
  }
  .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: ($size/2);;
    border-bottom-right-radius: ($size/2);;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
    //animation: loading-2 1.5s linear forwards 1.8s;
  }
  .progress-right {
    right: 0;
    .progress-bar {
      left: -100%;
      border-top-left-radius: ($size/2);;
      border-bottom-left-radius: ($size/2);;
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
      //animation: loading-1 1.8s linear forwards;
    }
  }
  .progress-value {
    display: flex;
    border-radius: 50%;
    font-size: 1rem;
    text-align: center;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 300;
    div {
      //margin-top: 10px;
    }
    span {
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}

/* This for loop creates the necessary css animation names 
Due to the split circle of progress-left and progress right, we must use the animations on each side. 
*/
@for $i from 1 through $howManySteps {
	$stepName: ($i*(100 / $howManySteps));

	//animation only the left side if below 50%
	@if $i <= ($howManySteps/2) { 
		.progress[data-percentage="#{$stepName}"] {
			.progress-right .progress-bar {
				 animation: loading-#{$i} $animationTime linear forwards;
			}
			.progress-left .progress-bar {animation: 0;}
		}
	}
	//animation only the right side if above 50%
	@if $i > ($howManySteps/2)  {  
		.progress[data-percentage="#{$stepName}"] {
			.progress-right .progress-bar {
				animation: loading-#{($howManySteps/2)} $animationTime linear forwards; //set the animation to longest animation
			}
			.progress-left .progress-bar {
      animation: loading-#{$i - ($howManySteps/2)} $animationTime linear forwards $animationTime;
    }
		}
	}
}

//animation
@for $i from 1 through ($howManySteps/2) { 
	$degrees: (180/($howManySteps/2));
	$degrees: ($degrees*$i);
	@keyframes loading-#{$i}{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate($degrees);
			transform: rotate(#{$degrees}deg);
    }
	}
}
