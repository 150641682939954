@import url("https://fonts.googleapis.com/css2?family=Sora:wght@200;300;400;500;600;700;800;900&display=swap");

// * {
//   font-family: "Sora", sans-serif !important;
// }

body {
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  color: #000000;
  font-family: "Sora", sans-serif !important;
}

html {
  scroll-behavior: smooth;
}

.ui.inverted.dimmer .ui.loader:after {
  border-color: theme-color("primary") transparent transparent;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #3cc3d8;
  // border-style: dashed;
}

.font-weight-semibold {
  font-weight: 600;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.drop-area {
  border: 1px dashed #c3d0db;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  margin: 10px 0;
  background: #f2f5f7;
  height: 352px;
}

.drop-area.hover {
  border-color: #007bff;
}

.rules-link {
  color: #007aff !important;
}

.rules-link:hover {
  color: #007aff !important;
}

@media (max-width: 991px) {
  .rp-1 {
    border-right: 0 !important;
  }

  .rp-2 {
    border-right: 0 !important;
  }
}

@media (max-width: 1199px) {
  .rd-1 {
    border-right: 0 !important;
    border-bottom: 1px solid #f2f2f2;
  }
}

.rule-select {
  width: 140px;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.pending {
  background: #f7961c;
}

.pending-alt {
  background: #fff5ec;
  border: 1px solid #b76b00;
  color: #b76b00;
}

.allowed {
  background: #00b27c;
}

.settled {
  background: #f0fffa;
  border: 1px solid #00b27c;
  color: #00b27c;
}

.text-black-75 {
  color: #3d5263;
}

.bg-black {
  background-color: black !important;
}

.bg-blue {
  background-color: #082e4d !important;
}

.btn-muted {
  background: #dfe3eb;
}

.border-muted {
  border: 1px solid #f2f3f4 !important;
}

$objectConfig: 0, top, bottom, left, right;

@each $tuple in $objectConfig {
  .border-#{($tuple)}-muted {
    border-#{($tuple)}: 1px solid #f2f3f4 !important;
  }
}

.static-save {
  @include media-breakpoint-down(sm) {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
    bottom: 0;
    background: white;
    padding: 15px 0;
    border-top: 1px solid #d3e4ef !important;
  }
}

.quantity input {
  width: 100%;
  float: left;
  display: block;
  padding: 0.625rem 10px 0.625rem 0.75rem;
  margin: 0;
  padding-right: 20px;
}

.quantity input:focus {
  outline: 0;
}

.quantity-nav {
  float: left;
  position: relative;
  height: 42px;
}

.quantity-button {
  position: relative;
  cursor: pointer;
  border-left: 1px solid #d3e3ef;
  width: 25px;
  text-align: center;
  color: #333;
  font-size: 17px;
  line-height: 1.4;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.quantity-button.quantity-up {
  position: absolute;
  height: 56%;
  top: 0;
  border-bottom: 1px solid #d3e3ef;
}

.quantity-button.quantity-down {
  position: absolute;
  bottom: -3px;
  height: 50%;
}

/////////////////////New image upload

.image-upload-wrap {
  .images {
    display: flex;
    flex-wrap: wrap;
    width: 559px;
    // min-height: 120px;
    min-height: 230px;
  }

  .images .img,
  .images .pic {
    /* flex-basis: 31%; */
    margin-bottom: 10px;
    border-radius: 4px;
  }

  .images .img {
    width: 100px;
    height: 100px;
    background-size: contain;
    border: 1px solid #6bdcce;
    background-repeat: no-repeat;
    margin-right: 10px;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .images .img:nth-child(5n) {
    margin-right: 0;
  }

  .images .img span {
    display: none;
    text-transform: capitalize;
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px;
    color: red;
  }

  .images .img span.check {
    display: block;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    padding: 4px;
    color: $primary;
  }

  .images .img span.default-image {
    background-color: #fff;
    color: #000;
    font-size: 10px;
    padding: 5px;
    position: relative;
    top: 0px;
    width: auto;
    right: 0;
    border-radius: 4px;
    cursor: pointer;
    z-index: 1;
  }

  .images .img::after {
    content: "";
    width: 100%;
    height: 100%;
    /* transition: opacity .1s ease-in; */
    border-radius: 4px;
    opacity: 0;
    position: absolute;
  }

  .images .img:hover::after {
    display: block;
    background-color: #000;
    opacity: 0.1;
  }

  .images .img:hover span {
    display: block;
    /* color: #fff; */
  }

  .images .pic {
    border: 1px dashed #01c7b1;
    background-color: #fff;
    text-align: center;
    padding: 40px 0;
    text-transform: uppercase;
    color: #848ea1;
    font-size: 12px;
    cursor: pointer;
    background-image: url("../images/picture.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 26px;
    width: 100px;
    height: 100px;
  }
}

.media-upload {
  width: 142px;
  height: 152px;

  &.new {
    width: 559px;
    height: 230px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  .top {
    width: 100%;
    height: 132px;
    background-image: url("../images/picture.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 46px;
    border: 1px dashed $primary;

    &.varient {
      border: 1px dashed #b9daf3;
      background-image: url("../images/photo2.png");
    }
  }
}

#manage-business {
  .upload-banner {
    // padding: 10px;
    // background: white;
    // box-sizing: border-box;
    // resize: horizontal;
    // border: 1px dashed;
    // overflow: auto;
    // max-width: 100%;
    // height: calc(100vh - 16px);

    .image-placeholder {
      margin: unset;
      border-radius: unset;
      width: 60vw;
      height: 20vw;
      max-width: 900px;
      max-height: 300px;
      position: relative;

      #img_contain {
        width: 100%;
        height: 100%;

        #blah {
          max-height: unset;
          object-fit: fill;
        }
      }

      .cover {
        background: #fbfcfd;
        top: 0;
        height: 100%;
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .custom-file {
        width: 100%;

        input {
          width: 100%;
          display: block;
        }
      }
    }
  }
}

.table {
  .media-display {
    width: unset;
    height: unset;

    .stack {
      position: relative;
      height: 79px;

      div {
        position: absolute;
        width: 70px;
        height: 70px;
        border: 1px solid $primary;
        background: white;

        &.stack1 {
          top: 8px;
          left: 8px;
        }

        &.stack2 {
          top: 4px;
          left: 4px;
        }

        &.stack3 {
          img {
            object-fit: scale-down;
            height: inherit;
            cursor: pointer;
            width: 100%;
            display: block;
            max-height: 132px;
          }
        }
      }
    }
  }

  .sub-attr1 {
    .main-attr {
      .attr-div {
        display: none;
      }

      .form-group label .remove-attr {
        display: none;
      }
    }
  }

  .images .img:nth-child(5n) {
    margin-right: 10px;
  }

  .media-upload {
    width: 70px;
    height: 70px;

    &.new {
      width: unset;
      height: unset;
    }

    .top {
      width: 100%;
      height: 70px;
      background-image: url("../images/picture.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 22px;
      border: 1px dashed $primary;

      &.varient {
        border: 1px dashed #b9daf3;
        background-image: url("../images/photo2.png");
        height: 230px;
        background-size: 38px;
      }
    }
  }
}

.media-display {
  width: 146px;
  height: 152px;

  .stack {
    position: relative;
    height: 145px;

    div {
      position: absolute;
      width: 132px;
      height: 132px;
      border: 1px solid $primary;
      background: white;

      &.stack1 {
        top: 8px;
        left: 8px;
      }

      &.stack2 {
        top: 4px;
        left: 4px;
      }

      &.stack3 {
        img {
          object-fit: scale-down;
          height: inherit;
          cursor: pointer;
          width: 100%;
          display: block;
          max-height: 132px;
        }
      }
    }
  }
}

.trumbowyg-button-pane {
  background: #ffffff !important;
  border-bottom: 1px solid #d3e4ee !important;
}

.trumbowyg-box,
.trumbowyg-editor {
  border: 1px solid #d3e4ee;
  background: #f7f8fa !important;
}

.trumbowyg-button-pane .trumbowyg-button-group::after {
  background: #d3e4ee;
}

.trumbowyg-box svg,
.trumbowyg-modal svg {
  fill: #7e8fa4 !important;
}

.trumbowyg-button-pane .trumbowyg-open-dropdown::after {
  border-top-color: #7e8fa4 !important;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  color: #c3c5c7 !important;
}

hr {
  margin-top: 3em;
  margin-bottom: 3rem;
}

hr.reducedHeight {
  margin-top: 2em;
  margin-bottom: 2rem;
}

.sticky-top {
  top: 65px;
}

#editor {
  height: 190px;
}

.ql-container.ql-snow {
  border-color: #d2e4ee !important;
}

.ql-toolbar.ql-snow {
  border: 1px solid #d2e4ee !important;

  .ql-stroke {
    stroke: #7e8fa4;
    // fill: #7e8fa4;
  }

  .ql-picker {
    color: #7e8fa4;
  }

  .ql-fill {
    fill: #7e8fa4;
    // stroke: #7e8fa4;
  }
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #01c7b1 !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #01c7b1 !important;
}

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #01c7b1 !important;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.multiselect-container > li > a > label {
  margin: 0;
  height: 100%;
  cursor: pointer;
  font-weight: 400;
  padding: 3px 20px 3px 12px !important;
}

.item-image {
  width: 100% !important;
  margin-bottom: 1rem;

  img {
    width: 100% !important;
    object-fit: scale-down !important;
    height: 156px !important;
    border: 1px solid #d2e4ef !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}

.language-html {
  line-height: 1.5 !important;
}

.feedbackDiv {
  height: 69px;
}

.bootstrap-tagsinput {
  display: flex !important;
  color: #495057 !important;
  background-color: #f7f8fa !important;
  background-clip: padding-box !important;
  border-color: #d3e4ef !important;
  padding: 0.625rem 10px 0.625rem 0.75rem !important;
  border-radius: 0 !important;
  box-shadow: none !important;

  span.badge-info {
    background-color: #00c8b1 !important;
    align-items: center !important;
    display: flex !important;
    text-transform: capitalize !important;
    margin-right: 6px !important;

    span {
      text-transform: lowercase !important;
    }
  }
}

select {
  height: 45px !important;
  @media (max-width: 1200px) {
    height: 33px !important;
  }
}

.highlight {
  background: #e8bdbd4f !important;
}

.power-input {
  width: 100px !important;
}

.power-edit i.fa-pencil-alt {
  opacity: 0;
  font-size: 12px;
  margin-top: 4px;
}

.table tbody tr:hover td span.currency {
  color: #7e8fa4;
  transition: color 0.3s ease-in-out;
}

.power-edit {
  &:hover {
    i.fa-pencil-alt {
      opacity: 1;
      font-size: 12px;
    }
  }
}

button:focus {
  outline: none;
}

.editable-submit {
  border: none;
  background: #01c7b1;
  color: #fff;
  height: 100%;
  width: 38px;
  margin: 0 10px;
  cursor: pointer;
}

.editable-cancel {
  color: #01c7b1;
  background: white;
  border-color: #01c7b1;
  height: 100%;
  width: 38px;
  border-style: solid;
  cursor: pointer;
}

.fa-info-circle {
  color: #bac9de;
  line-height: 11px;
  font-size: 1em;
}

// Cedi sign
span {
  &.cedi {
    &::before {
      content: "GH₵";
      font-size: smaller;
    }
  }

  &.kes {
    &::before {
      content: "KES";
      font-size: smaller;
    }
  }
}

span.currency {
  font-size: smaller;
  margin-right: 5px;
}

.tel_num {
  display: inline;
  max-width: 46px;
  text-align: center;
  margin: 2.5px;
  height: 46px;
  padding: 0.5rem 0.2rem !important;
}

.custom-select {
  position: relative;

  > input {
    background: #f7f8fa url("../images/caret.svg") no-repeat right 0.75rem
      center;
    background-size: 11px;

    // &:focus {

    //   +.list {
    //     display: block;
    //   }
    // }

    // +.list {
    //   display: none;
    // }
  }

  .list {
    background: white;
    position: absolute;
    top: -2px;
    width: 100%;
    display: none;
    z-index: 1;

    p {
      padding: 19px;
      margin: 0;
      cursor: pointer;

      &:hover {
        background: #f7f8fb;
      }
    }

    div {
      margin: 0 19px 19px 19px;

      input {
        width: 100%;
        width: -moz-available;
        /* For Mozzila */
        width: -webkit-fill-available;
        /* For Chrome */
        width: stretch;
      }

      button {
        position: absolute;
        right: 0;
        top: 8px;
        margin-right: 10px;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.min-height-19 {
  min-height: 18.85rem;
}

ul.multiselect-container.dropdown-menu {
  overflow: auto;
  max-height: 314px;
}

.grid-action {
  height: 2rem;
  height: 2rem;
  position: absolute;
  right: 39px;
  top: 2rem;

  @media (max-width: 578px) {
    position: relative;
    right: unset;
    top: unset;
  }

  i {
    font-size: 1.3rem;
    cursor: pointer;
  }
}

.special-alert {
  display: flex;
  border-left: 6px solid $primary;
  align-items: center;

  img {
    height: 29px;
  }

  p {
    margin: 0;
    flex: 1;
  }
}

.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.currency-transfer {
  span {
    position: absolute;
    color: #7e8fa4;
    padding-top: 15px;
    font-weight: 700;
    font-size: 12px;
  }
}

span.field-validation-error {
  color: red;
  font-size: small;
}

.profile_image {
  .inner {
    img {
      object-fit: cover;
      width: 120px;
      height: 120px;
    }
  }

  .badge-circle {
    background: $primary;
    color: #fff;
    border: 2px solid #fff;
    position: absolute;
    width: 27px !important;
    bottom: 7px;
    right: 6px;
    padding: 11.5px 0 !important;
  }
}

.input-group.date {
  input {
    padding-right: 36px;
  }

  .input-group-append {
    right: 1px;
    z-index: 5;
  }
}

$bagdeConfig: "teal" $primary, "bronze" #bc7e27, "silver" rgb(144, 160, 181),
  "gold" #f8b517, "platinum" #424959;

@each $tuple in $bagdeConfig {
  .badge-check-#{nth($tuple, 1)} {
    background: nth($tuple, 2) !important;
    color: white;
    border: 2px solid white;
    position: absolute;
    width: 27px !important;
    bottom: 7px;
    right: 6px;
    padding: 11.5px 0 !important;
  }
}

$bagdeConfig: "teal" $primary, "bronze" #bc7e27, "silver" rgb(144, 160, 181),
  "gold" #f8b517, "danger" #ef5350, "platinum" #424959;

@each $tuple in $bagdeConfig {
  .badge-pill-#{nth($tuple, 1)} {
    background: white;
    color: nth($tuple, 2) !important;
    border: 1px solid nth($tuple, 2);
    font-size: 14px !important;
    font-weight: normal !important;
    padding: 7px 14px !important;
    margin-top: 11px;
  }
}

$bagdeConfig: "teal" $primary, "bronze" #bc7e27, "silver" rgb(144, 160, 181),
  "gold" #f8b517, "platinum" #424959;

@each $tuple in $bagdeConfig {
  .progress-bar.#{nth($tuple, 1)} {
    background: nth($tuple, 2);
  }
}

.fa-ellipsis-h {
  transition: background-color 0.3s ease-in-out;
  width: 40px;
  padding: 13px 13px;
  height: 40px;
  border-radius: 50%;
  color: $primary;
}

a:focus {
  outline: none !important;
}

.breadcrumb {
  background-color: transparent !important;
}

.ui-sortable-helper {
  cursor: grab !important;
}

.card-search {
  form,
  .form-inline {
    max-width: 950px;
    margin: auto;

    input {
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      transition: all 0.5s ease;
      float: right;

      &:focus {
        width: 250px;

        @media (max-width: 578px) {
          width: 100%;
        }
      }
    }
  }
}

i.fa.fa-trash.text-danger.pointer {
  transition: background-color 0.3s ease-in-out;
  width: 40px;
  padding: 13px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 1px 5px 0 #d6d2d6;
  top: 12px;
  position: relative;
  opacity: 1;
}

.tooltip-inner {
  max-width: 249px;
  padding: 1rem;
  color: #7d8ea4;
  box-shadow: 0 3px 25px 1px #d4e4f0;
  text-align: center;
  background-color: #fff;
  border-radius: 0;
}

.full-height-fix {
  height: calc(100vh - 220px);
}

// @include media-breakpoint-down(md) {
//   transform: translateX(0);
//   .nav-scroller {
//       position: relative;
//       z-index: 2;
//       height: 2.75rem;
//       overflow-y: hidden;
//       padding-left: ($grid-gutter-width / 2);
//       padding-right: ($grid-gutter-width / 2);
//   }
//   .nav-scroller .nav {
//       display: -ms-flexbox;
//       display: flex;
//       -ms-flex-wrap: nowrap;
//       flex-wrap: nowrap;
//       padding-bottom: 1rem;
//       margin-top: -1px;
//       overflow-x: auto;
//       text-align: center;
//       white-space: nowrap;
//       -webkit-overflow-scrolling: touch;
//   }
//   .nav-scroller .nav-link {
//       padding-top: .75rem;
//       padding-bottom: .75rem;
//       font-size: .875rem;
//   }
//   .navbar-nav {
//       flex-direction: row;
//   }
// }

// @include media-breakpoint-down(md) {
// .nav-scroller {
//   height: 3.75rem;
//   .navbar-nav {
//     margin-bottom: 1.25rem;
//   }
//   .nav-link {
//     padding: 10px 15px;
//     .nav-link-icon {
//       width: 24px;
//       height: 24px;
//       margin: 0 auto;
//     }
//   }
//   .active {
//     .nav-link {
//       background-color: #fff;
//       position: relative;
//       &::before {
//         content: "";
//         width: 100%;
//         height: 3px;
//         background-color: theme-color("primary");
//         position: absolute;
//         top: 0;
//         left: 0;
//       }
//     }
//   }
// }
// }

/*--------------
    Progress Bar
---------------*/
.progressbar {
  counter-reset: step;
}

.progressbar li {
  list-style: none;
  display: inline-block;
  width: 30.33%;
  position: relative;
  text-align: center;
  // cursor: pointer;

  @include media-breakpoint-down(sm) {
    width: 49.33%;
  }
}

.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #d4e4ef;
  color: #8998ab;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #ddd;
  top: 15px;
  left: -50%;
  z-index: 0;
}

.progressbar li:first-child:after {
  content: none;
}

.progressbar li.active {
  color: $primary;
}

.progressbar li.active:before {
  border-color: $primary;
  background: $primary;
  color: white;
  font-weight: 700;
}

.progressbar li.active + li:after {
  background-color: #d4e4ef;
}

/*--------------
    Custom Progress Bar
---------------*/
.customprogressbar {
  counter-reset: step;
}

.customprogressbar li {
  list-style: none;
  display: inline-block;
  width: 19.33%;
  position: relative;
  text-align: center;
  // cursor: pointer;

  @include media-breakpoint-down(sm) {
    width: 18.33%;
  }
}

.new-features {
  ol {
    max-width: 400px;
    counter-reset: stepper;
    list-style: none;
  }
  ol li {
    margin-top: 20px;
    counter-increment: stepper;
    position: relative;
  }
  ol li::before {
    content: counter(stepper);
    color: white;
    font-weight: bold;
    position: absolute;
    --size: 32px;
    left: calc(-1 * var(--size) - 10px);
    line-height: var(--size);
    width: var(--size);
    height: var(--size);
    top: 5px;
    background: $primary;
    border-radius: 50%;
    text-align: center;
  }
}

.customprogressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #d4e4ef;
  color: #8998ab;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.customprogressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #ddd;
  top: 15px;
  left: -50%;
  z-index: 0;
}

.customprogressbar li:first-child:after {
  content: none;
}

.customprogressbar li.active:before {
  border-color: $primary;
  background: $primary;
  color: white;
  font-weight: 700;
}

.customprogressbar li.active + li:after {
  background-color: #d4e4ef;
}

.customprogresscomplete:before {
  content: "\2713" !important;
  border-color: $primary !important;
  background: $primary !important;
  color: white !important;
  font-weight: 700 !important;
}

span.notify {
  padding: 10px 7px;
  line-height: 0;
  text-align: center;
  position: absolute;
  top: 0;
  right: -3px;
  border-radius: 10px;

  &.feedback-notify {
    top: -10px;
    left: 69px;
    right: unset;

    @include media-breakpoint-down(md) {
      top: 0px;
      left: unset;
      right: 0;
    }
  }
}

.item-attr > div:nth-child(even) {
  margin-left: 8.3333333333%;

  @include media-breakpoint-down(md) {
    margin-left: unset;
  }
}

.border-dashed {
  border: 1px dashed #c3d7e6 !important;
}

.messageID {
  height: 34px;
  width: 34px;
  padding: 5px 9px;
  margin-bottom: 6px;
}

.toggle-days {
  display: flex;
  flex-wrap: wrap;

  .btn-outline-primary {
    width: 70px;
    margin-right: -1px;

    color: #516e90;
    border-color: #dbe8f2;
    border-left: none;
    // border-right: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:first-of-type {
      border-radius: 5px 0px 0px 5px;
      border-left: 1px solid #dbe8f2;
    }

    &:last-of-type {
      border-radius: 0px 5px 5px 0px;
      border-right: 1px solid #dbe8f2;
    }

    &.active {
      color: #01c7b1 !important;
      background-color: #e6faf7 !important;
      border-color: #01c7b1 !important;
      border-left: 1px solid #01c7b1 !important;
      border-right: 1px solid #01c7b1 !important;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    &:hover {
      color: #01c7b1 !important;
      background-color: #e6faf7 !important;
      border-color: #01c7b1 !important;
      border-left: 1px solid #01c7b1 !important;
      border-right: 1px solid #01c7b1 !important;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 24px;
  height: 24px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: #01c7b1;
  outline: none;
  background: #ffffff;
  border-radius: 50%;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: #01c7b1;
  outline: none;
  background: #ffffff;
  border-radius: 50%;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 0.3;

  opacity: 0.75;
  color: #01c7b1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "‹";
}

[dir="rtl"] .slick-prev:before {
  content: "›";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "›";
}

[dir="rtl"] .slick-next:before {
  content: "‹";
}

.form-group.custom {
  select {
    position: absolute;
    width: 80px !important;
    min-width: unset;
    background-color: white;
    border-bottom: none;
  }

  input {
    padding-left: 99px;
  }
}

.invoiceAdd-div {
  overflow: auto;

  @include media-breakpoint-up(xl) {
    overflow: unset !important;
  }

  @include media-breakpoint-up(lg) {
    overflow: auto;
  }

  @include media-breakpoint-down(sm) {
    overflow: unset;
  }

  .invoiceAdd-div-inner {
    min-width: 910px;

    @include media-breakpoint-up(md) {
      min-width: 910px !important;
    }

    @include media-breakpoint-down(md) {
      min-width: unset;
    }

    .invoice-addition {
      .order {
        display: flex;
        align-items: flex-end;
        margin-bottom: 12px;
        margin-right: 20px;

        @include media-breakpoint-down(md) {
          align-items: flex-start;
        }

        span {
          height: 25px;
          width: 25px;
          padding: 2px 8px;
        }
      }

      button {
        height: 30px;
        width: 30px;
        padding: 2px 2px;
        margin-bottom: 9px;
      }

      .form-group.custom {
        select {
          position: absolute;
          width: 86px !important;
          min-width: unset;
          background-color: white;
          border-bottom: none;
        }

        input {
          padding-left: 87px;
        }
      }

      input,
      select {
        min-width: 210px;
      }

      select {
        padding-right: 38px;
      }
    }
  }
}

.assign-channel {
  table {
    td > .add {
      display: none;
    }

    tr:hover {
      cursor: pointer;

      td > .money {
        // display: none;
      }

      td > .add {
        display: block;
      }
    }
  }
}

.dataTables_filter {
  display: none;
}

#itemlist_info {
  float: right;
}

#itemlist_paginate {
  margin: 20px 0;
}

.paginate_button {
  padding: 0 9px;
  color: #000000;
  cursor: pointer;

  &.current {
    font-weight: bold;
    text-decoration: underline;
    color: $primary;
  }
}

table.dataTable {
  border-bottom: 1px solid #d3e4ef;
}

div.dataTables_wrapper div.dataTables_paginate {
  text-align: left;
}

.huebee__cursor {
  width: 21px;
  height: 21px;
}

.track-recon {
  position: relative;

  .progress {
    height: 53px;
    background-color: transparent;
    overflow: initial;

    .progress-bar {
      background-color: #abefe7;
      border-radius: 8px;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }
  }

  .hours {
    display: flex;
    // position: absolute;
    // width: 100%;
    // top: 17px;

    div {
      flex-grow: 1;
      font-weight: bold;
      text-align: center;
      height: 53px;
      align-items: center;
      display: flex;
      justify-content: center;
      color: #858484;

      &.selected {
        background-color: #abefe7;
        box-shadow: 3px 3px 0.25rem rgba(0, 0, 0, 0.075) !important;
        cursor: pointer;
        color: black;
      }
    }
  }
}

.reconcile-upload {
  .custom-file,
  .custom-file-input {
    height: calc(1.5em + 0.75rem + 12px);
  }

  .custom-file-label {
    height: calc(1.5em + 0.75rem + 12px);
    border: 1px solid #d3e4ef;
  }

  .custom-file-input:lang(en) ~ .custom-file-label:after {
    content: "Upload";
  }

  .custom-file-label:after {
    background-color: #01c7b1;
    color: white;
    height: calc(1.5em + 0.75rem + 10px);
  }

  .custom-file-label,
  .custom-file-label:after {
    line-height: 2.1;
  }
}

.varient-body {
  tr {
    &.active {
      background: #f7f8fb;
    }
  }
}

.filter-form {
  z-index: 1;
}

.account_div {
  border: 1px dashed #b0cfe0;
  width: 87%;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  .top {
    padding: 28px 33px;
    background: #f2f4fb;
    border-bottom: 1px dashed #b0cfe0;
  }

  .info {
    padding: 28px 28px 0 33px;
  }

  &:hover {
    box-shadow: 2px 3px 4px #e4e4e4;
  }
}

#simulator {
  .web-content {
    background-image: url(../images/web-mockup.png);
    height: 616px;
    width: 96%;
    margin: auto;
    max-width: 1077px;
    background-size: contain;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
  }

  .mobile-content {
    background-image: url(../images/hubtelapp.png);
    height: 610px;
    width: 315px;
    background-size: cover;
    background-repeat: no-repeat;
    margin: auto;

    &.android {
      background-image: url(../images/andriod-device.png);
    }

    .inner {
      height: 100%;
      padding: 88px 26px 33px 27px;
      width: 100%;
    }

    &.posios {
      background-image: url(../images/posios.png);
    }

    &.ussdios {
      background-image: url(../images/posiosalt.png);
      height: 627px;
    }
  }
}

.accordion h2 {
  img {
    transition: all 0.5s;
  }

  &.active {
    img {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
}

.service-modal {
  position: relative;
  width: 100%;
  top: -28px;

  > span {
    right: -10px;
    font-size: 21px !important;
    padding: 5px 10px;
    cursor: pointer;
    position: absolute;
  }

  .service-header {
    margin: 0 -16px;
    padding: 22px 36px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d3e3ef;
  }
}

.reciept-offline {
  width: 100%;
  margin: 76px auto 2px;

  @include media-breakpoint-up(md) {
    width: 400px;
  }

  @include media-breakpoint-up(lg) {
    width: 500px;
  }
}

.saved-order {
  .table tr td:first-child,
  .table tr th:first-child {
    padding-left: 5px;
  }

  .fixed_header {
    table-layout: fixed;
    border-collapse: collapse;
  }

  .fixed_header tbody {
    display: block;
    width: 100%;
    overflow: auto;
    max-height: 146px;
  }

  .fixed_header tfoot {
    display: block;
    width: 100%;
  }

  .fixed_header thead tr {
    display: block;
  }

  .fixed_header td:last-child {
    text-align: right;
    padding-right: 0;
    white-space: nowrap;
  }

  .fixed_header thead > tr > th:nth-of-type(2) {
    width: 54%;
  }

  .fixed_header tbody > tr > td:nth-of-type(2) {
    width: 54%;
  }

  .fixed_header th,
  .fixed_header td {
    padding: 10px;
    text-align: left;
    width: 200px;
  }
}

.dashboard-tabs {
  .nav-tabs {
    justify-content: space-between;
    flex-wrap: nowrap;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }

    .nav-item {
      &::after {
        content: " ";
        top: 19%;
        width: 1px;
        right: 0;
        position: absolute;
        background-color: #e6eaed;
      }

      .nav-link {
        &.active {
          background: #f2f3f4;

          &::after {
            height: 4px;
            bottom: unset;
            top: -1px;
            border-radius: 0 0 90px 90px;
          }
        }

        &:hover {
          background: #f2f3f4;
        }
      }
    }
  }
}

.filter-form {
  // box-shadow: rgb(236, 236, 236) 0px 1px 6px inset;
  // background-color: #f8f9fa!important;

  input,
  select {
    // background-color: #ffffff!important;
  }

  .form-group {
    label {
      display: block;
    }

    .btn-group {
      display: block;
    }
  }
}

.bulkUploadFile {
  @media (max-width: 1024px) {
    // padding-right: 5rem;
  }

  @media (max-width: 768px) {
    border: none !important;
  }

  .uploadDiv {
    height: 301px;
    border: 1px dashed $primary;
    display: flex;
    align-items: center;
    justify-content: center;

    h6.file_name {
      width: 100%;
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-word;
      word-break: break-word;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }
  }

  .imgInp {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    opacity: 0;
    height: 100%;
    background-color: #d3d3d3;
    cursor: pointer;

    &.bulk {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
    }
  }

  .uploadIcon {
    padding: 20% 10%;

    img {
      max-width: 162px;
      width: 100%;
    }
  }
}

//custom scroll styling
#scroll {
  overflow-x: auto;
  overflow-y: auto;

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #d5d7d8;
    }
  }

  &::-webkit-scrollbar-track {
    width: 8px;
    // -webkit-box-shadow: inset 0 0 6px #dee2e6;
    border-radius: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    margin: 50px 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 6px;
    // -webkit-box-shadow: inset 0 0 6px #dee2e6;
    background-color: transparent;
  }
}

//custom scroll styling
#scroll-x {
  overflow-x: auto;
  overflow-y: hidden;

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #d5d7d8;
    }
  }

  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px #dee2e6;
    width: 8px;
    border-radius: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    // width: 2px;
    height: 6px;
    margin: 20px 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    // -webkit-box-shadow: inset 0 0 6px #dee2e6;
    background-color: transparent;
  }
}

#scroll-y {
  overflow-x: hidden;
  overflow-y: auto;

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #d5d7d8;
    }
  }

  &::-webkit-scrollbar-track {
    width: 8px;
    // -webkit-box-shadow: inset 0 0 6px #dee2e6;
    border-radius: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    // width: 2px;
    height: 6px;
    margin: 20px 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 6px;
    // -webkit-box-shadow: inset 0 0 6px #dee2e6;
    background-color: transparent;
  }
}

.hide-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.personalized {
  height: calc(100vh - 420px);
  // padding-top: 53px;
  // min-height: 600px;

  @media (max-width: 992px) {
    height: 100%;
    padding-top: 11px;
  }

  .personalized-div {
    border: 1px solid #cce1ef;
    border-radius: 6px;
    padding: 25px 30px;
    width: 100%;
    margin-right: auto;
    display: block;
    height: 100%;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;

    @media (min-width: 1200px) {
      // width: 80%;
    }

    &:hover {
      // border: 1px solid #01c7b1;
      text-decoration: none;
      -webkit-transition: all 0.8s ease-out;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease-out;
      background-color: #fbfbfb !important;
    }
  }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.business-branch {
  .btn-group-toggle .btn-account-box.active {
    background: #ccf3ef5d !important;
    color: #01c7b1 !important;
  }

  .btn-group-toggle .btn-account-box i {
    right: -6px;
  }
}

.btn-group-toggle {
  .disabled {
    color: #cacaca;
    cursor: not-allowed;
    z-index: 99;
    pointer-events: none;
  }
}

#messaging {
  .hubtelband {
    position: relative;
    color: #364052;
    -webkit-box-shadow: -1px 17px 14px -10px rgba(122, 120, 122, 1);
    -moz-box-shadow: -1px 17px 14px -10px rgba(122, 120, 122, 1);
    box-shadow: -1px 17px 14px -10px rgba(122, 120, 122, 1);

    &.platinum {
      color: #b2b9c0;
    }

    .loyalband {
      border-right: 1px solid;

      @media (max-width: 578px) {
        border: none !important;
      }

      @media (max-width: 767.98px) {
        border: none !important;
      }
    }

    .card-info {
      position: absolute;
      left: 6%;
      bottom: 31.7%;

      h4 {
        font-size: 1.6rem;
      }

      p {
        font-size: 0.9rem;
      }

      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        bottom: 1.7%;
        line-height: 2px;

        h4 {
          margin-bottom: 11px !important;
          font-size: 1.1rem;
        }
      }

      @media (max-width: 767px) {
        left: 20px;

        h4 {
          margin-bottom: 11px;
          font-size: 0.9375rem;
        }

        h6 {
          font-size: 0.75rem;
        }

        p {
          font-size: 0.625rem;
        }
      }
    }
  }
}

.support {
  .chat-text {
    p {
      background: #e6ebf1;
      padding: 17px;
      display: inline-block;
      clear: both;
      border-radius: 0 14px 14px 14px;
      margin-bottom: 5px;

      //   &::after {
      //     content: '';
      //     position: absolute;
      //     left: 21px;
      //     top: 13px;
      //     width: 0;
      //     height: 0;
      //     border: 26px solid transparent;
      //     border-right-color: #e6ebf1;
      //     border-left: 0;
      //     border-top: 0;
      //     margin-top: -13px;
      //     margin-left: -26px;

      //   }
    }
  }
}

.user {
  .chat-text {
    p {
      float: right;
      background: #47b6f9;
      padding: 17px;
      color: white;
      display: inline-block;
      clear: both;
      border-radius: 14px 0 14px 14px;
      margin-bottom: 5px;

      //   &::after {
      //     content: '';
      //     position: absolute;
      //     right: 21px;
      //     top: 13px;
      //     width: 0;
      //     height: 0;
      //     border: 26px solid transparent;
      //     border-left-color: #47b6f9;
      //     border-right: 0;
      //     border-top: 0;
      //     margin-top: -13px;
      //     margin-right: -26px;

      //   }
    }
  }
}

/**---------------------------------------------------------------------------------------------------------**/
/*														RESIZER STYLESHEET														*/
/**---------------------------------------------------------------------------------------------------------**/
.croppie-container {
  width: 100%;
  height: 100%;
}

.croppie-container .cr-image {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0 0;
  max-height: none;
  max-width: none;
}

.croppie-container .cr-boundary {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  position: absolute;
  border: 2px solid #fff;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.croppie-container .cr-resizer {
  z-index: 2;
  box-shadow: none;
  pointer-events: none;
}

.croppie-container .cr-resizer-vertical,
.croppie-container .cr-resizer-horisontal {
  position: absolute;
  pointer-events: all;
}

.croppie-container .cr-resizer-vertical::after,
.croppie-container .cr-resizer-horisontal::after {
  display: block;
  position: absolute;
  box-sizing: border-box;
  border: 1px solid black;
  background: #fff;
  width: 10px;
  height: 10px;
  content: "";
}

.croppie-container .cr-resizer-vertical {
  bottom: -5px;
  cursor: row-resize;
  width: 100%;
  height: 10px;
}

.croppie-container .cr-resizer-vertical::after {
  left: 50%;
  margin-left: -5px;
}

.croppie-container .cr-resizer-horisontal {
  right: -5px;
  cursor: col-resize;
  width: 10px;
  height: 100%;
}

.croppie-container .cr-resizer-horisontal::after {
  top: 50%;
  margin-top: -5px;
}

.croppie-container .cr-original-image {
  display: none;
}

.croppie-container .cr-vp-circle {
  border-radius: 50%;
}

.croppie-container .cr-overlay {
  z-index: 1;
  position: absolute;
  cursor: move;
  touch-action: none;
}

.croppie-container .cr-slider-wrap {
  width: 75%;
  margin: 15px auto;
  text-align: center;
}

.croppie-result {
  position: relative;
  overflow: hidden;
}

.croppie-result img {
  position: absolute;
}

.croppie-container .cr-image,
.croppie-container .cr-overlay,
.croppie-container .cr-viewport {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

/*************************************/
/***** STYLING RANGE INPUT ***********/
/*************************************/
/*http://brennaobrien.com/blog/2014/05/style-input-type-range-in-every-browser.html */
/*************************************/

.cr-slider {
  -webkit-appearance: none;
  /*removes default webkit styles*/
  /*border: 1px solid white; */ /*fix for FF unable to apply focus style bug */
  width: 300px;
  /*required for proper track sizing in FF*/
  max-width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: transparent;
}

.cr-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 3px;
}

.cr-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ddd;
  margin-top: -6px;
}

.cr-slider:focus {
  outline: none;
}
/*
.cr-slider:focus::-webkit-slider-runnable-track {
background: #ccc;
}
*/

.cr-slider::-moz-range-track {
  width: 100%;
  height: 3px;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 3px;
}

.cr-slider::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ddd;
  margin-top: -6px;
}

/*hide the outline behind the border*/
.cr-slider:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

.cr-slider::-ms-track {
  width: 100%;
  height: 5px;
  background: transparent;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  border-color: transparent; /*leave room for the larger thumb to overflow with a transparent border */
  border-width: 6px 0;
  color: transparent; /*remove default tick marks*/
}
.cr-slider::-ms-fill-lower {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.cr-slider::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.cr-slider::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ddd;
  margin-top: 1px;
}
.cr-slider:focus::-ms-fill-lower {
  background: rgba(0, 0, 0, 0.5);
}
.cr-slider:focus::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.5);
}
/*******************************************/

/***********************************/
/* Rotation Tools */
/***********************************/
.cr-rotate-controls {
  position: absolute;
  bottom: 5px;
  left: 5px;
  z-index: 1;
}
.cr-rotate-controls button {
  border: 0;
  background: none;
}
.cr-rotate-controls i:before {
  display: inline-block;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
}
.cr-rotate-l i:before {
  content: "↺";
}
.cr-rotate-r i:before {
  content: "↻";
}

span.badge-info-light {
  background-color: #ccf3ef !important;
}
span.badge-orange {
  background-color: #efe638c2 !important;
}

.free-riders {
  position: fixed;
  z-index: 9999;
  bottom: 30px;
  left: 40px;
  width: 330px;

  .top {
    // padding: ;
  }

  .bottom {
    max-height: 200px;
    overflow: auto;

    div {
      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}

//slick
.slick-slider {
  margin-bottom: 0;

  .payment-cards {
    height: auto;
    border-radius: 10px;

    img.bg {
      height: 150px !important;
      border-radius: 10px;
    }

    img.banklogo {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 60px;
    }

    img.cardtype {
      position: absolute;
      bottom: 9px;
      right: 16px;
      width: 39px;
    }

    .info {
      bottom: 40%;
      left: 5%;

      @media (max-width: 576px) {
        bottom: 33%;
      }

      h5 {
        @media (max-width: 576px) {
          font-size: 1rem;
        }
      }
    }
  }

  .slick-slide {
    opacity: 0.2;
    // max-width: 219px;
    width: 230px;
    height: 150px;
    border-radius: 10px;
    // overflow: hidden;
    cursor: pointer;
    transform: translateX(0);
    transition: all 0.5s ease;
    margin-right: 26px;
    // transition: all .5s ease;

    @media (max-width: 576px) {
      // max-width: 130px;
    }

    .info {
      h5 {
        background: #fff;
        color: #000;
        padding: 5px 8px;
        border-radius: 0.25rem !important;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        font-size: smaller;
      }
    }

    &.add-new-wallet {
      opacity: 1;
      box-shadow: none !important;

      a {
        background: #f0f0f0;
        color: #222;
        border-radius: 10px;
        text-align: center;
        padding: 2.9rem;
        color: $link-color;
        border: 1px solid rgba(1, 199, 177, 0.15);
        height: 100%;

        &.ngx {
          padding: 2.7rem;
        }
      }
    }

    &.slick-current {
      opacity: 1;

      .payment-cards {
        position: relative;

        // margin-bottom: 14px;
        // top: -21px;
        // max-width: 276px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4),
          0 3px 11px 0 rgba(0, 0, 0, 0.53);
        // background-image: url(../images/tick.png);

        @media (max-width: 576px) {
          // max-width: 205px;
        }

        &.add-new-wallet {
          box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0),
            0 3px 6px 0px rgba(0, 0, 0, 0.18);
        }

        &:after {
          content: "";
          position: absolute;
          top: 15px;
          right: 0;
          bottom: 0;
          left: 15px;
          background: url(../images/tick.png) no-repeat;
          z-index: 1;
        }

        &.add-new-wallet:after {
          content: none;
        }
      }
    }
  }

  .slick-prev,
  .slick-next {
    top: 45%;
  }

  .slick-prev {
    left: -28px;
  }

  .slick-next {
    right: -24px;
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 30px;
    line-height: 0.8;
    opacity: 0.51;
    color: #01c7b1;
    font-weight: 100;
    // border: 1px solid $link-color;
    border-radius: 50%;
    display: block;
    width: 25px;
    height: 25px;
    opacity: 1;
  }

  .slick-next.slick-disabled:before,
  .slick-prev.slick-disabled:before {
    opacity: 0.3;
  }

  .slick-next:before {
    content: "›";
  }

  .slick-prev:before {
    content: "‹";
  }

  .slick-list {
    margin: 0;
    padding: 22px 12px !important;
    // padding: 40px 10px 0 !important;
  }

  @media (min-width: 768px) {
    .slick-list {
      // padding: 40px 10px 0 !important;
      // margin: 0 20px;
    }
  }
}

.payment-cards.add-new-wallet {
  height: 150px !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0),
    0 3px 6px 0px rgba(0, 0, 0, 0.18);

  &:after {
    content: "";
    position: absolute;
    top: 15px;
    right: 0;
    bottom: 0;
    left: 15px;
    z-index: 1;
  }

  &:focus {
    outline: none !important;
  }

  a {
    z-index: 11;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.filled-6 {
  letter-spacing: 4px;
}

.paylink-card {
  height: 450px !important;

  @media (max-width: 576px) {
    height: auto !important;
  }

  .select-card {
    max-height: 188px !important;

    @media (max-width: 576px) {
      max-height: fit-content !important;
    }
  }

  .select-payments {
    height: 435px;

    @media (max-width: 576px) {
      height: auto !important;
      max-height: 435px;
    }
  }

  .bank-card {
    max-height: 285px;

    @media (max-width: 576px) {
      max-height: fit-content;
    }
  }
}

.checkout-card {
  .select-card {
    max-height: 188px !important;

    @media (max-width: 576px) {
      max-height: fit-content !important;
    }
  }
}

.checkout-body {
  height: 100vh !important;

  @media (max-width: 576px) {
    height: unset !important;
  }
}

.closeAlert {
  float: right;
  font-size: 1.5rem;
  font-weight: 200;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

button.closeAlert {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.text-blue {
  color: #007aff;
}

.text-secondary-grey {
  color: #3d5263;
}

.options-disabled {
  color: #bbb !important;
}

.svg-disabled {
  g {
    circle {
      fill: #e8ebf075 !important;
    }
    g {
      path {
        fill: #bbb !important;
      }
    }
  }
}

.new-messaging {
  background-image: url(../../../app/dist/images/new_messaging_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 140px !important;

  @media (max-width: 578px) {
    height: auto !important;
  }
}

.payment-imgs {
  width: 60px;
  height: 60px;
}

.list-style-inside {
  list-style-position: inside;
}

.assigned-rider-table {
  min-height: fit-content !important;
}

.text-green {
  color: #468d38;
}

.bg-green {
  background: #468d38;
}

.bg-light-gold {
  background: #fff4cc;
}

.checkout-accordion {
  .card-header {
    label {
      padding: 1.5rem;
    }

    .form-check-input[type="radio"] + label {
      padding-left: 30px !important;
      &:before {
        top: 27px;
        left: 23px;
      }

      &:after {
        top: 31px;
        left: 27px;
      }
      span {
        margin-left: 1.25rem;
      }
    }
  }
}
.table-image-wrapper {
  width: 30px;
  height: 30px;
}

.table-image-wrapper img {
  width: 100%;
  height: 100%;
}
.text-gold {
  color: #f89520 !important;
}

.vh-75 {
  height: 75vh !important;
  @include media-breakpoint-down(sm) {
    height: auto !important;
  }
}

.upload-image-wrapper {
  width: 100%;
  height: 400px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.form-check {
  input[type="checkbox"] {
    accent-color: #01c7b2;
  }
}

.bg-lightgreen {
  background: #dbffdb;
}

.bg-grey {
  background: #b7c2cd;
}
.sticky-date {
  position: sticky;
  top: 5px;
  z-index: 1;
  padding: 10px 0;
  margin-bottom: 10px;
}
.sticky-date-pill {
  width: 134px;
  text-align: center;
}

.notif-menu > .dropdown-menu {
  margin-top: 19px !important;
  margin-right: -53px !important;
  min-width: 400px;
  border-radius: 8px;

  @media (max-width: 567px) {
    min-width: 360px;
  }

  ul {
    height: 400px;

    li {
      cursor: pointer;
    }
  }

  &::after {
    content: "";
    border-top: 25px solid transparent;
    border-bottom: 20px solid #fff;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    width: 0;
    height: 0;
    right: 55px;
    top: -20px;
    margin-top: -20px;
  }
}

.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}
